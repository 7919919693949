import React, { Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import "./assets/css/common.css";
const AboutUs = React.lazy(() => import("./pages/AboutUs"));
const ContactUs = React.lazy(() => import("./pages/contactus"));
const Landing = React.lazy(() => import("./pages/landing"));
const OurServices = React.lazy(() => import("./pages/services"));
const PortFolio = React.lazy(() => import("./pages/PortFolio"));
const WindowTint = React.lazy(() => import("./pages/WindowTint"));
const Detailing = React.lazy(() => import("./pages/Detailing/Detailing"));
const NewCarPreparation = React.lazy(() => import("./pages/NewCarPreparation"));
const PortfolioAlbum = React.lazy(() => import("./pages/PortfolioAlbum/PortfolioAlbum"));

const RestoreCarPreparation = React.lazy(() =>
  import("./pages/RestoreCarPreparation")
);

function App() {
  return (
    <React.Fragment>
      <Suspense fallback={<div className="loading" />}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="/contactus" element={<ContactUs />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/portfolio" element={<PortFolio />} />
          <Route path="/portfolio/services/album" element={<PortfolioAlbum />} />
          <Route path="/services/window-tint" element={<WindowTint />} />
          <Route path="/detailing" element={<Detailing />} />
          <Route
            path="/services-new-car-preparation"
            element={<NewCarPreparation />}
          />
          <Route
            path="/services-restore-car-preparation"
            element={<RestoreCarPreparation />}
          />
        </Routes>
      </Suspense>
    </React.Fragment>
  );
}

export default App;
